import React from 'react';
import Layout from '../components/layout/Layout';

const NotFoundPage = () => (
  <Layout>
    <section className='flex mx-auto my-10 bg-transparent'>
      <div className='mx-auto px-4 align-middle'>
        <h2 className='text-7xl text-center text-white font-semibold'>
          Not Found
        </h2>
        <div className='max-w-xs mx-auto align-middle pt-6'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='white'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
            />
          </svg>
        </div>
        <h2 className='text-5xl text-center text-white'>
          Sorry, this page does not exist
        </h2>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
